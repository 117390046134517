
import AccountsRepo from './AccountsRepo';

const repositories = {
    AccountsRepo: AccountsRepo,
}

export const AccRepositoryFactory = {
    get: name => repositories[name],
};
