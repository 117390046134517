
import ClientsRepo from './ClientsRepo';

const repositories = {
    ClientsRepo: ClientsRepo,
}

export const ClientRepositoryFactory = {
    get: name => repositories[name],
};
