<template>
  <div id="clients">
    <h2 class="text-end section_title mb-5">الحسابات - العملاء</h2>
    <div class="table_container">
      <table
          v-if="!addNewLine"
          width="100%"
          dir="rtl"
          class="table table-bordered table-striped all_clients_table"
      >
        <tbody>
        <tr>
          <th class="text-end">اﻻسم</th>
          <th class="text-end">الموبيل</th>
          <th class="text-end">نوع العميل</th>
          <th class="text-end">العنوان</th>
          <th class="text-end">المدينة</th>
          <th class="text-end">اﻻيميل</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="client in clients" :key="client.ID" class="disabled_input">
          <td>
            <input v-model="client.name" placeholder="اﻻسم"/>
          </td>
          <td>
            <input v-model="client.mobile" placeholder="الموبايل"/>
          </td>
          <td>
            <input v-bind:value="$filters.translatedType(client.type)" placeholder="نوع العميل"/>
            <!--            <vue-select v-model="client.type" :options="transfer_client_options"-->
            <!--                        search-placeholder="نوع العميل"-->
            <!--                        close-on-select></vue-select>-->
          </td>
          <td>
            <input v-model="client.address" placeholder="العنوان"/>
          </td>
          <td>
            <input v-model="client.city" placeholder="المدينة"/>
          </td>
          <td>
            <input v-model="client.email" placeholder="اﻻيميل"/>
          </td>
          <td>
            <button class="edit_btn btn btn-dark" @click="editClient($event, client)"><i class="bi bi-gear-fill"></i>
            </button>
            <button class="save_btn btn btn-success" @click="updateClient($event, client)">
              حفظ
            </button>
          </td>
          <td>
            <button class="btn btn-danger" @click="deleteClient(client.ID)"><i class="bi bi-trash-fill"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div>
      <add-new-line :repo="repo" :addNewLine="addNewLine" :lineType="lineType"/>
    </div>
    <!--    <div class="text-end py-3">-->
    <!--      <button class="btn btn-secondary" v-if="addNewLine" @click="addNewLine = false">إلغاء اﻻضافة</button>-->
    <!--      <button class="btn btn-primary" v-else @click="addLineForNewClient">إضافة عميل</button>-->
    <!--    </div>-->
  </div>
</template>

<script>
import AddNewLine from './../components/AddNewLine.vue'
import {ClientRepositoryFactory} from "./../services/repositories/ClientsApi/RepositoryFactory.js";

let clientsRepo = ClientRepositoryFactory.get('ClientsRepo')
export default {
  name: 'Company',
  components: {
    AddNewLine
  },
  data() {
    return {
      items: [],
      addNewLine: false,
      clients: [],
      lineType: '',
      transfer_client_options: [
        {
          name: 'شركات',
          value: 'company'
        },
        {
          name: 'أفراد',
          value: 'individual'
        },
      ],
      repo: clientsRepo
    }
  },
  methods: {
    addLineForNewClient: function () {
      if (document.querySelectorAll('.all_clients_table tr:not(.disabled_input)').length > 1) {
        let isConfirmed = confirm(`لم يتم الحفظ , هل تريد اﻻستكمال ؟`)
        if (!isConfirmed) {
          return
        }
      }
      this.addNewLine = true
      this.lineType = 'عميل'
    },
    editClient: function (ev, client) {
      // ev.path[2].classList.remove("disabled_input")
      this.$router.replace(`/add-client/${client.ID}`)
    },
    updateClient: function (ev, client) {
      ev.path[2].classList.add("disabled_input")
      let data = new FormData()
      data.append('email', client.email)
      data.append('city', client.city)
      data.append('address', client.address)
      data.append('mobile', client.mobile)
      data.append('name', client.name)
      data.append('type', client.type.value)
      let isConfirmed = confirm(`هل توافق على اجراء التعديل ؟`)
      if (isConfirmed) {
        clientsRepo.update(data, client.ID).then((val) => {
          console.log(val)
          alert('تم التعديل بنجاح')
        })
      } else {
        this.$router.go()
      }
    },
    deleteClient: function (id) {
      let isConfirmed = confirm(`هل توافق على اجراء التعديل ؟`)
      if (isConfirmed) {
        clientsRepo.delete(id).then(() => {
          alert('تم التعديل بنجاح')
          this.$router.go()
        })
      }
    }
  },
  mounted: function () {
    clientsRepo.index().then((value) => {
      this.clients = value.data.data.clients
      console.log(this.clients)
    })
  },
}
</script>

<style>

</style>