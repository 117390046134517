<template>
  <div id="safe">
    <h2 class="text-end section_title mb-5">الحسابات - الخزنة - الفرع</h2>
    <table width="100%" dir="rtl" class="table table-bordered table-striped data_table">
      <tbody>
      <tr>
        <th width="65%" class="text-center">الفرع</th>
        <th class="text-end">المبلغ</th>
      </tr>
      <tr>
        <td class="">فودافون</td>
        <td class="text-end">6000</td>
      </tr>
      <tr>
        <td class="">اتصاﻻت</td>
        <td class="text-end">5000</td>
      </tr>
      <tr>
        <td class="text-start">اﻻجمالى</td>
        <td class="text-end">5000</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'Safe',
  data() {
    return {}
  },
  methods: {}
}
</script>

<style>

</style>