<template>
  <div id="ٍSettings">
    <h2 class="text-end section_title mb-5">الاعدادت</h2>
  </div>
</template>

<script>

export default {
  name: 'ٍSettings',
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>