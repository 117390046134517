<template>
  <div id="vendors">
    <h2 class="text-end section_title mb-5">إضافة إيراد</h2>
    <div>
      <add-new-line :repo="repo" :cancel="'income'" :addNewLine="addNewLine" :lineType="lineType"/>
    </div>
  </div>
</template>

<script>
import AddNewLine from './../components/AddNewLine.vue'
import {RepositoryFactory} from "@/services/repositories/PaymentsApi/RepositoryFactory";

let incomesRepo = RepositoryFactory.get('incomesRepo')

export default {
  name: 'AddExpanses',
  components: {
    AddNewLine
  },
  data() {
    return {
      addNewLine: true,
      lineType: 'وارد',
      repo: incomesRepo,
    }
  },
  methods: {
  },
  mounted: function () {

  },
}
</script>

<style>

</style>