<template>
  <div id="company">
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&amp;display=swap"
      rel="stylesheet"
    />
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
    />
    <div class="page-holder">
      <div class="container-fluid px-lg-4 px-xl-5 contentDiv">
        <!-- Page Header-->
        <div class="page-header mb-4">
          <h2 class="text-end section_title mb-5">الصفحة الشخصية</h2>
        </div>
        <section>
          <div class="row">
            <div class="col-lg-8">
              <div class="card overflow-hidden mb-4">
                <div class="card-header">
                  <div class="input-group">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Message"
                    />
                    <button class="btn btn-outline-secondary" type="button">
                      <i class="fa fa-paper-plane"></i>
                    </button>
                  </div>
                </div>
                <div class="list-group rounded-0">
                  <div
                    class="
                      list-group-item
                      border-start-0 border-end-0
                      py-5
                      border-top-0
                    "
                  >
                    <div class="d-flex">
                      <div class="flex-shrink-0">
                        <img
                          class="avatar avatar-lg p-1"
                          src="https://therichpost.com/wp-content/uploads/2021/03/avatar2.png"
                          alt="Jassa Rich"
                        />
                      </div>
                      <div class="flex-grow-1 ps-3">
                        <small class="float-right">10 mins ago</small>
                        <h5 class="fw-bold">Jassa Rich</h5>
                        <div class="text-muted text-sm">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </div>
                        <div class="d-flex mt-4">
                          <div class="flex-shrink-0">
                            <img
                              class="avatar avatar-md p-1"
                              src="https://therichpost.com/wp-content/uploads/2021/03/avatar2.png"
                              alt="Serenity Mitchelle"
                            />
                          </div>
                          <div class="flex-grow-1 ps-3 text-sm text-muted">
                            <strong class="text-dark"
                              >Serenity Mitchelle: </strong
                            >Lorem Ipsum is simply dummy text of the printing
                            and typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </div>
                        </div>
                        <div class="d-flex mt-4">
                          <div class="flex-shrink-0">
                            <img
                              class="avatar avatar-md p-1"
                              src="https://therichpost.com/wp-content/uploads/2021/03/avatar2.png"
                              alt="Tony O'Brian"
                            />
                          </div>
                          <div class="flex-grow-1 ps-3 text-sm text-muted">
                            <strong class="text-dark">Tony O'Brian: </strong
                            >Lorem Ipsum is simply dummy text of the printing
                            and typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item border-start-0 border-end-0 py-5">
                    <div class="d-flex">
                      <div class="flex-shrink-0">
                        <img
                          class="avatar avatar-lg p-1"
                          src="https://therichpost.com/wp-content/uploads/2021/03/avatar2.png"
                          alt="Jassa Rich"
                        />
                      </div>
                      <div class="flex-grow-1 ps-3">
                        <small class="float-right">12 mins ago</small>
                        <h5 class="fw-bold">Jassa Rich</h5>
                        <div class="text-muted text-sm">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list-group-item border-start-0 border-end-0 py-5">
                    <div class="d-flex">
                      <div class="flex-shrink-0">
                        <img
                          class="avatar avatar-lg p-1"
                          src="https://therichpost.com/wp-content/uploads/2021/03/avatar2.png"
                          alt="Jassa Rich"
                        />
                      </div>
                      <div class="flex-grow-1 ps-3">
                        <small class="float-right">34 mins ago</small>
                        <h5 class="fw-bold">Jassa Rich</h5>
                        <div class="text-muted text-sm">
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book.
                        </div>
                        <div class="d-flex mt-4">
                          <div class="flex-shrink-0">
                            <img
                              class="avatar avatar-md p-1"
                              src="https://therichpost.com/wp-content/uploads/2021/03/avatar2.png"
                              alt="Javier Gregory"
                            />
                          </div>
                          <div class="flex-grow-1 ps-3 text-sm text-muted">
                            <strong class="text-dark">Javier Gregory: </strong
                            >Lorem Ipsum is simply dummy text of the printing
                            and typesetting industry. Lorem Ipsum has been the
                            industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and
                            scrambled it to make a type specimen book.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <form class="card mb-4">
                <div class="card-header">
                  <h4 class="card-heading">Edit Profile</h4>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="mb-4">
                        <label class="form-label">Company</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Company"
                          value="Jassa Rich"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3">
                      <div class="mb-4">
                        <label class="form-label">Username</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Username"
                          value="Jassa"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4">
                      <div class="mb-4">
                        <label class="form-label">Email address</label>
                        <input
                          class="form-control"
                          type="email"
                          placeholder="Email"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6">
                      <div class="mb-4">
                        <label class="form-label">First Name</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="First name"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6">
                      <div class="mb-4">
                        <label class="form-label">Last Name</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="mb-4">
                        <label class="form-label">Address</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Home Address"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-4">
                      <div class="mb-4">
                        <label class="form-label">City</label>
                        <input
                          class="form-control"
                          type="text"
                          placeholder="City"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-3">
                      <div class="mb-4">
                        <label class="form-label">ZIP</label>
                        <input
                          class="form-control"
                          type="number"
                          placeholder="ZIP"
                        />
                      </div>
                    </div>
                    <div class="col-md-5">
                      <div class="mb-4">
                        <label class="form-label">Country</label>
                        <select class="form-control custom-select">
                          <option value="">UK</option>
                          <option value="">US</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="mb-0">
                        <label class="form-label">About Me</label>
                        <textarea
                          class="form-control"
                          rows="5"
                          placeholder="Here can be your description"
                        >
Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</textarea
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer text-end">
                  <button class="btn btn-primary" type="submit">
                    Update Profile
                  </button>
                </div>
              </form>
            </div>
            <div class="col-lg-4">
              <form class="card mb-4">
                <div class="card-header">
                  <h4 class="card-heading text-end">صفحتى</h4>
                </div>
                <div class="card-body">
                  <div class="row mb-3">
                    <div class="col-auto d-flex align-items-center">
                      <img
                        class="avatar avatar-lg p-1"
                        src="https://therichpost.com/wp-content/uploads/2021/03/avatar2.png"
                        alt="Avatar"
                      />
                    </div>
                    <div class="col">
                      <label class="form-label">Name</label>
                      <input class="form-control" placeholder="Your name" />
                    </div>
                  </div>
                  <!-- <div class="mb-3">
                    <label class="form-label">Bio</label>
                    <textarea class="form-control" rows="8">
Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</textarea
                    >
                  </div> -->
                  <div class="mb-3">
                    <label class="form-label">Email</label>
                    <input class="form-control" placeholder="you@domain.com" />
                  </div>
                  <label class="form-label">Password</label>
                  <input
                    class="form-control"
                    type="password"
                    value="password"
                  />
                </div>
                <div class="card-footer text-end">
                  <button class="btn btn-primary">Save</button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <footer class="footer bg-white shadow align-self-end py-3 px-xl-5 w-100">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 text-center text-md-start fw-bold">
              <p class="mb-2 mb-md-0 fw-bold">Your company © 2021</p>
            </div>
            <div class="col-md-6 text-center text-md-end text-gray-400">
              <!-- <p class="mb-0">Jassa</p> -->
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "Company",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.card-header:first-child {
  border-radius: calc(1rem - 1px) calc(1rem - 1px) 0 0;
}
.card-header {
  position: relative;
  padding: 2rem 2rem;
  border-bottom: none;
  background-color: white;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
  z-index: 2;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: none;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  border-radius: 1rem;
}
.bg-gray-100 {
  background-color: #f8f9fa !important;
}
body {
  font-family: "Poppins" !important;
}
.text-primary {
  color: #4650dd !important;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  line-height: 1.2;
}
.text-muted {
  color: #6c757d !important;
}
.lead {
  font-size: 1.125rem;
  font-weight: 300;
}
.text-sm {
  font-size: 0.7875rem !important;
}
h3,
.h3 {
  font-size: 1.575rem;
}
.page-holder {
  display: flex;
  overflow-x: hidden;
  width: 100%;
  min-height: calc(100vh - 72px);

  flex-wrap: wrap;
}
a {
  color: #4650dd !important;
  text-decoration: underline !important;
  cursor: pointer;
}
.card-profile-img {
  position: relative;
  max-width: 8rem;
  margin-top: -6rem;
  margin-bottom: 1rem;
  border: 3px solid #fff;
  border-radius: 100%;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  z-index: 2;
}
img,
svg {
  vertical-align: middle;
}
.avatar.avatar-lg {
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
}
.avatar {
  display: inline-block;
  position: relative;
  width: 3rem;
  height: 3rem;
  text-align: center;
  border: #dee2e6;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  line-height: 3rem;
}
.form-control {
  color: #343a40;
}
.page-heading {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 300;
}

.card-profile .card-header {
  height: 9rem;
  background-position: center center;
  background-size: cover;
}
</style>