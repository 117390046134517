<template>
  <div id="vendors">
    <h2 class="text-end section_title mb-5">إضافة حساب</h2>
    <div>
      <add-new-line :repo="repo" :cancel="'company_accounts'" :addNewLine="addNewLine" :lineType="lineType"/>
    </div>
  </div>
</template>

<script>
import AddNewLine from './../components/AddNewLine.vue'
import {AccRepositoryFactory} from "@/services/repositories/AccountsApi/RepositoryFactory";

let accountsRepo = AccRepositoryFactory.get('AccountsRepo')

export default {
  name: 'AddِAccount',
  components: {
    AddNewLine
  },
  data() {
    return {
      addNewLine: true,
      lineType: 'حساب',
      repo: accountsRepo,
    }
  },
  methods: {
  },
  mounted: function () {

  },
}
</script>

<style>

</style>