<template>
  <div id="vendors">
    <h2 class="text-end section_title mb-5">الحسابات - الموردين</h2>
    <div class="table_container">
      <table
          v-if="!addNewLine"
          width="100%"
          dir="rtl"
          class="table table-bordered table-striped all_vendors_table"
      >
        <tbody>
        <tr>
          <th class="text-end">اﻻسم</th>
          <th class="text-end">الموبيل</th>
          <th class="text-end">نوع البيزنس</th>
          <th class="text-end">العنوان</th>
          <th class="text-end">المدينة</th>
          <th class="text-end">اﻻيميل</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="vendor in vendors" :key="vendor.ID" class="disabled_input">
          <td >
            <input v-model="vendor.name" placeholder="اﻻسم"/>
          </td>
          <td >
            <input v-model="vendor.mobile" placeholder="الموبايل"/>
          </td>
          <td >
            <input v-bind:value="$filters.translatedType(vendor.type)" placeholder="نوع مقدم الخدمة"/>
<!--            <vue-select v-model="vendor.type" label-by="name" :options="transfer_vendor_options"-->
<!--                         search-placeholder="نوع مقدم الخدمة"-->
<!--                        close-on-select></vue-select>-->
          </td>
          <td >
            <input v-model="vendor.address" placeholder="العنوان"/>
          </td>
          <td >
            <input v-model="vendor.city" placeholder="المدينة"/>
          </td>
          <td >
            <input v-model="vendor.email" placeholder="اﻻيميل"/>
          </td>
          <td>
            <button class="edit_btn btn btn-dark" @click="editVendor($event, vendor)"><i class="bi bi-gear-fill"></i></button>
            <button class="save_btn btn btn-success" @click="updateVendor($event, vendor)">
              حفظ
            </button>
          </td>
          <td>
            <button class="btn btn-danger" @click="deleteVendor(vendor.ID)"><i class="bi bi-trash-fill"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div>
      <add-new-line :repo="repo" :addNewLine="addNewLine" :lineType="lineType"/>
    </div>
<!--    <div class="text-end py-3">-->
<!--      <button class="btn btn-secondary" v-if="addNewLine" @click="addNewLine = false">إلغاء اﻻضافة</button>-->
<!--      <button class="btn btn-primary" v-else @click="addLineForNewVendor">إضافة مورد</button>-->
<!--    </div>-->
  </div>
</template>

<script>
import AddNewLine from './../components/AddNewLine.vue'
import {VendorRepositoryFactory} from "./../services/repositories/VendorsApi/RepositoryFactory.js";

let vendorsRepo = VendorRepositoryFactory.get('VendorsRepo')
export default {
  name: 'Company',
  components: {
    AddNewLine
  },
  data() {
    return {
      items: [],
      addNewLine: false,
      vendors: [],
      lineType: '',
      transfer_vendor_options: [
          {
            name:'انتقاﻻت',
            value: 'transfer'
          },
          {
            name:'فندق',
            value: 'hotel'
          },
          {
            name:'اخرى',
            value: 'other'
          },
      ],
      repo: vendorsRepo
    }
  },
  methods: {
    addLineForNewVendor: function () {
      if (document.querySelectorAll('.all_vendors_table tr:not(.disabled_input)').length > 1) {
        let isConfirmed = confirm(`لم يتم الحفظ , هل تريد اﻻستكمال ؟`)
        if (!isConfirmed) {
          return
        }
      }
      this.addNewLine = true
      this.lineType = 'مورد'
    },
    editVendor: function (ev, vendor) {
      // ev.path[2].classList.remove("disabled_input")
      this.$router.replace(`/add-vendor/${vendor.ID}`)
    },
    updateVendor: function (ev, vendor) {
      ev.path[2].classList.add("disabled_input")
      let data = new FormData()
      data.append('email', vendor.email)
      data.append('city', vendor.city)
      data.append('address', vendor.address)
      data.append('mobile', vendor.mobile)
      data.append('name', vendor.name)
      data.append('type', vendor.type.value)
      let isConfirmed = confirm(`هل توافق على اجراء التعديل ؟`)
      if (isConfirmed) {
        vendorsRepo.update(data, vendor.ID).then(() => {
          alert('تم التعديل بنجاح')
        })
      } else {
        this.$router.go()
      }
    },
    deleteVendor: function (id) {
      let isConfirmed = confirm(`هل توافق على اجراء التعديل ؟`)
      if (isConfirmed) {
        vendorsRepo.delete(id).then(() => {
          alert('تم التعديل بنجاح')
          this.$router.go()
        })
      }
    }
  },
  mounted: function () {
    vendorsRepo.index().then((value) => {
      this.vendors = value.data.data.vendors
      console.log(this.vendors)
    })
  },
}
</script>

<style>

</style>