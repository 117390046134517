import Repository from '../Repository';

const resource = "/payments/";
export default {
     index() {
        return Repository.get(`${resource}/?type=income`);
    },
    get(id = null) {
        return Repository.post(`${resource}/get/${id}`);
    },
    add(data = null) {
        return Repository.post(`${resource}/create/`, data);
    },
    update(data, id) {
        return Repository.post(`${resource}/update/${id}`, data);
    },
    delete(id) {
        return Repository.post(`${resource}/delete/${id}`);
    },
    page(data) {
        return Repository.post(`${resource}/forgot/`, data);
    },
}
