import Repository from '../Repository';

const resource = "/accounts";
export default {
    index(search='') {
        // return Repository.post(`${resource}/token/`, data);
        return Repository.get(`${resource}/?search=${search}`);
    },
    get(id) {
        return Repository.get(`${resource}/get/${id}`);
    },
    add(data = null) {
        return Repository.post(`${resource}/create/`, data);
    },
    update(data, id) {
        return Repository.post(`${resource}/update/${id}`, data);
    },
    delete(id) {
        return Repository.post(`${resource}/delete/${id}`);
    },
    page(data) {
        return Repository.post(`${resource}/forgot/`, data);
    },
}
