<template>
  <div id="clients">
    <h2 class="text-end section_title mb-5">الحجوزات</h2>
    <div class="table_container">
      <table
          width="100%"
          dir="rtl"
          class="table table-bordered table-striped all_clients_table"
      >
        <tbody>
        <tr>
          <th class="text-end">رقم الحجز</th>
          <th class="text-end">إجمالى قيمة الحجز</th>
          <th class="text-end">المدفوع</th>
          <th class="text-end">على حساب</th>
          <th class="text-end"></th>
          <th class="text-end"></th>
        </tr>
        <tr v-for="resv in reservations" :key="resv.ID" class="disabled_input">
          <td width="15%">
            <input v-model="resv.ID"/>
          </td>
          <td width="15%">
            <input v-model="resv.total"/>
          </td>
          <td width="20%">
            <input v-model="resv.paid_amount"/>
          </td>
          <td width="20%">
            <input v-model="resv.account_id"/>
          </td>
          <td class="text-center">
            <button class="edit_btn text-center btn btn-dark" @click="editReserve($event, resv)"><i class="bi bi-gear-fill"></i>
            </button>
          </td>
          <td class="text-center">
            <button class="btn text-center btn-danger" @click="deleteReserve(resv.ID)"><i class="bi bi-trash-fill"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div>
      <add-new-line :repo="repo" :addNewLine="addNewLine" :lineType="lineType"/>
    </div>
    <div class="text-end py-3">
      <!--      <button class="btn btn-secondary" v-if="addNewLine" @click="addNewLine = false">إلغاء اﻻضافة</button>-->
      <!--      <button class="btn btn-primary" v-else @click="addLineForNewClient">إضافة عميل</button>-->
    </div>
  </div>
</template>

<script>
import AddNewLine from './../components/AddNewLine.vue'
import {ReservationRepositoryFactory} from "@/services/repositories/ReservationApi/RepositoryFactory";

let reservationRepo = ReservationRepositoryFactory.get('reservationRepo')
export default {
  name: 'Company',
  components: {
    AddNewLine
  },
  data() {
    return {
      items: [],
      addNewLine: false,
      reservations: [],
      lineType: '',
    }
  },
  methods: {
    editReserve: function (ev, resv) {
      this.$router.replace(`/new-reserve/${resv.ID}`)
    },
    deleteReserve: function (id) {
      let isConfirmed = confirm(`هل توافق على اجراء التعديل ؟`)
      if (isConfirmed) {
        reservationRepo.delete(id).then(() => {
          alert('تم التعديل بنجاح')
          this.$router.go()
        })
      }
    },
  },
  mounted: function () {
    reservationRepo.index().then((value) => {
      this.reservations = value.data.data.reservations
    })
  },
}
</script>

<style>

</style>