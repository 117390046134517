import UserRepo from './UserRepo';
import ProfileRepo from './ProfileRepo';
import AuthRepo from './AuthRepo';

const repositories = {
    auth: AuthRepo,
    user: UserRepo,
    profile: ProfileRepo,
}

export const RepositoryFactory = {
    get: name => repositories[name],
};
