<template>
  <div id="login" class="">
      <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-12 col-md-8 col-lg-6 col-xl-5">
            <div class="card bg-custom-yellow text-white" style="border-radius: 1rem;">
              <div class="card-body p-5 text-center">

                <div class="mb-md-5 mt-md-4 pb-5">

                  <h2 class="fw-bold mb-2 text-uppercase">تسجيل الدخول</h2>
                  <p class="text-white-50 mb-5">برجاء إدخال إسم المستخدم وكلمة المرور</p>

                  <div class="form-outline form-white mb-4">
                    <input type="email" id="typeEmailX" name="username" v-model="input.username" placeholder="إسم المستخدم" class="form-control form-control-lg"/>
                    <label class="form-label d-none" for="typeEmailX">إسم المستخدم</label>
                  </div>

                  <div class="form-outline form-white mb-4">
                    <input type="password" id="typePasswordX" name="password" v-model="input.password" placeholder="كلمة المرور" class="form-control form-control-lg"/>
                    <label class="form-label d-none" for="typePasswordX">كلمة المرور</label>
                  </div>

<!--                  <p class="small mb-5 pb-lg-2"><a class="text-white-50" href="#!">Forgot password?</a></p>-->

                  <button class="btn btn-outline-light btn-lg px-5"  v-on:click="login()" type="submit">دخول</button>
                  <div v-html="login_response" v-bind:class="{ 'mt-4 text-danger': login_response }"></div>
                </div>

                <div>
                  <p class="mb-0">لتسجيل مستخدم جديد<router-link  to="/register" class="text-white-50 mx-2 fw-bold">التسجيل</router-link></p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import {RepositoryFactory} from "./../services/repositories/AuthAPI/RepositoryFactory";

let authRepo = RepositoryFactory.get('auth')
export default {
  name: 'Login',
  data() {
    return {
      input: {
        username: "",
        password: ""
      },
      results: {},
      login_response: ''
    }
  },
  methods: {
    login: function() {
      let data = new FormData()
      data.append('email', this.input.username)
      data.append('password', this.input.password)
      authRepo.login(data).then((results) => {
        let responseMSG = results.data.result
        if(responseMSG == 'SUCCESS') {
          this.results = results.data
          localStorage.setItem('isAuthenticated', true);
          localStorage.setItem('accessToken', this.results.token);
          this.$router.replace(`/home`)
        }
        else {
            this.login_response = results.data.data
        }
      }).catch((erorr) => {
        console.log(erorr)
      })
    }
  }
}
</script>

<style scoped>
#login {
  font-family: cairo;
}
</style>