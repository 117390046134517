
import reservationRepo from './ReservationRepo';

const repositories = {
    reservationRepo: reservationRepo,
}

export const ReservationRepositoryFactory = {
    get: name => repositories[name],
};
