<template>
  <div id="clients">
    <h2 class="text-end section_title mb-5">الحسابات - حسابات الشركة</h2>
    <div class="table_container">
   <table
      v-if="!addNewLine"
      width="100%"
      dir="rtl"
      class="table table-bordered table-striped all_accounts_table"
    >
      <tbody>
        <tr>
          <th class="text-end">اسم الحساب</th>
          <th class="text-end">رقم الحساب</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="account in accounts" :key="account.ID" class="disabled_input">
          <td >
            <input v-model="account.name" placeholder="اسم الحساب" />
          </td>
          <td >
            <input v-model="account.number" placeholder="رقم الحساب" />
          </td>
          <td class="text-center">
            <button class="edit_btn btn btn-dark" @click="editAccount($event, account)"><i class="bi bi-gear-fill"></i></button>
            <button class="save_btn btn btn-success" @click="updateAccount($event, account)">
              حفظ
            </button>
          </td>
          <td class="text-center">
            <button class="btn btn-danger" @click="deleteAccount(account.ID)"><i class="bi bi-trash-fill"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
    <div>
      <add-new-line :repo="repo" :cancel="'company_accounts'" :addNewLine="addNewLine" :lineType="lineType" />
    </div>
    <div class="text-end py-3">
<!--      <button class="btn btn-secondary" v-if="addNewLine" @click="addNewLine = false">إلغاء اﻻضافة</button>-->
      <button class="btn btn-primary" v-if="!addNewLine"  @click="addLineForNewAccount">إضافة حساب</button>
    </div>
  </div>
</template>

<script>
import AddNewLine from '../components/AddNewLine.vue'
import {AccRepositoryFactory} from "./../services/repositories/AccountsApi/RepositoryFactory";
let accountsRepo = AccRepositoryFactory.get('AccountsRepo')
export default {
  name: 'Company',
  components: {
      AddNewLine
  },
  data() {
    return {
        items:[],
        addNewLine:false,
        accounts:[],
        lineType: 'حساب',
        repo:accountsRepo
    }
  },
  methods: {
      addLineForNewAccount:function () {
        //  if(document.querySelectorAll('.all_accounts_table tr:not(.disabled_input)').length > 1) {
        //   let isConfirmed = confirm(`لم يتم الحفظ , هل تريد اﻻستكمال ؟`)
        //   if(!isConfirmed) {
        //     return
        //   }
        // }
        // this.addNewLine = true
        // this.lineType = 'حساب'
        this.$router.replace('/add-account')
      },
      editAccount: function (ev, account) {
        // ev.path[2].classList.remove("disabled_input")
        this.$router.replace(`/add-account/${account.ID}`)
      },
      updateAccount: function (ev, account) {
        ev.path[2].classList.add("disabled_input")
        let data = new FormData()
        data.append('name', account.name)
        data.append('number', account.number)
        let isConfirmed = confirm(`هل توافق على اجراء التعديل ؟`)
        if(isConfirmed) {
          accountsRepo.update(data,account.ID).then(()=>{
            alert('تم التعديل بنجاح')
          })
        }
        else {
          this.$router.go()
        }     
      },
      deleteAccount: function (id) {
        let isConfirmed = confirm(`هل توافق على اجراء التعديل ؟`)
        if(isConfirmed) {
          accountsRepo.delete(id).then(()=>{
            alert('تم التعديل بنجاح')
            this.$router.go()
          })
        }    
      }
  },
  mounted: function(){
    accountsRepo.index().then((value)=>{
        this.accounts = value.data.data.accounts
    })
  },
}
</script>

<style >

</style>