<template>
  <div id="vendors">
    <h2 class="text-end section_title mb-5">إضافة عميل</h2>
    <div>
      <add-new-line :repo="repo" :cancel="'clients'" :addNewLine="addNewLine" :lineType="lineType"/>
    </div>
  </div>
</template>

<script>
import AddNewLine from './../components/AddNewLine.vue'
import {ClientRepositoryFactory} from "@/services/repositories/ClientsApi/RepositoryFactory";

let clientRepo = ClientRepositoryFactory.get('ClientsRepo')

export default {
  name: 'AddClient',
  components: {
    AddNewLine
  },
  data() {
    return {
      addNewLine: true,
      lineType: 'عميل',
      repo: clientRepo,
    }
  },
  methods: {
  },
  mounted: function () {

  },
}
</script>

<style>

</style>