import axios from "axios"
// import store from '@/store';

//const baseDomain = "/api/v1"
const baseDomain = "https://portal.royalhills-eg.com/api/"
// const baseDomain = "http://localhost/royal-hills/"
const baseURL = `${baseDomain}`

// let token = store.getters.accessToken;

const instence = axios.create({
    baseURL,
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
        // 'Accept': 'text/html,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8',
        // 'Access-Control-Allow-Header': '*',
        // 'Access-Control-Allow-Origin': '*'
    }
});

instence.interceptors.request.use(
    (config) => {
      let token = localStorage.getItem('accessToken')
  
      if (token) {
        config.headers['Authorization'] = `Bearer ${ token }`
        // config.headers['Authorization'] = `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyZXN1bHQiOiJTVUNDRVNTIiwiZGF0YSI6eyJpZCI6IjEiLCJuYW1lIjoiYWRtaW4iLCJlbWFpbCI6ImFkbWluQHRlc3QuY29tIiwicGFzc3dvcmQiOiIyMDJjYjk2MmFjNTkwNzViOTY0YjA3MTUyZDIzNGI3MCIsInN0YXR1cyI6IjAiLCJ0b2tlbiI6ImV5SjBlWEFpT2lKS1YxUWlMQ0poYkdjaU9pSklVekkxTmlKOS5leUp5WlhOMWJIUWlPaUpUVlVORFJWTlRJaXdpWkdGMFlTSTZleUpwWkNJNklqRWlMQ0p1WVcxbElqb2lZV1J0YVc0aUxDSmxiV0ZwYkNJNkltRmtiV2x1UUhSbGMzUXVZMjl0SWl3aWNHRnpjM2R2Y21RaU9pSXlNREpqWWprMk1tRmpOVGt3TnpWaU9UWTBZakEzTVRVeVpESXpOR0kzTUNJc0luTjBZWFIxY3lJNklqQWlMQ0owYjJ0bGJpSTZJaUlzSW5KdmJHVmZhV1FpT2lJeEluMTkuTDBSVXZrTW51b0owT1Y5R1FhQ1hTd2E0TVI3N0tZMUFTcHVTcmNBREZJMCIsInJvbGVfaWQiOiIxIn19.d4D1oHzYNFty_f5GMFUwTZcIb-b0LmGOGDEaiYSRgX0`

        // config.headers['Access-Control-Allow-Origin'] = `*`
        // config.headers['withCredentials'] = true 
        // config.headers['Access-Control-Allow-Headers'] = `Origin, X-Requested-With, Content-Type, Accept, Authorization`
      }
  
      return config;
    }, 
  
    (error) => {
      return Promise.reject(error);
    }
  );
  
// Add a response interceptor
instence.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if(response.data.message == "Please login!") {
        window.location.href = '/login';
    }
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  });

export default instence