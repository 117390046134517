import Repository from './../Repository';

const resource = "";
export default {
    login(data) {
        // return Repository.post(`${resource}/token/`, data);
        return Repository.post(`${resource}/login/`, data);
    },
    get(id = null) {
        return Repository.post(`${resource}/get/${id}`);
    },
    logout(data = null) {
        return Repository.post(`${resource}/logout/`, data);
    },
    verify(data) {
        return Repository.post(`${resource}/token/verify/`, data);
    },
    signup(data) {
        return Repository.post(`${resource}/registration/`, data);
    },
    forgot(data) {
        return Repository.post(`${resource}/forgot/`, data);
    },
    reset(data) {
        return Repository.put(`${resource}/reset/`, data);
    },
}
