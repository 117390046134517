<template>
  <div id="Ensure-Reserve">
    <h2 class="text-end section_title mb-5">تأكيد الحجوزات</h2>
  </div>
</template>

<script>

export default {
  name: 'Ensure-Reserve',
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>

</style>