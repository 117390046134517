<template>
  <div id="welcome">
    <div class="d-flex">
      <h2 class="text-end section_title mb-5">التقارير الخاصة بالمدة الزمنية</h2>
<!--      <div class="d-flex  justify-content-between align-content-center">-->
<!--        <span class="mx-2 mt-1 cairo_font">من:</span>-->
<!--        <Datepicker utc  placeholder="بداية من ...." :enableTimePicker="false"  v-model="start_date" locale="en" />-->
<!--        <span class="mx-2 mt-1 cairo_font">إلى:</span>-->
<!--        <Datepicker placeholder="وصولا إلى ...." :enableTimePicker="false"  v-model="end_date" locale="en" />-->
<!--      </div>-->
    </div>
    <div class="row">
      <div class="col-md-4 col-xl-3">
        <div class="card bg-c-blue order-card">
          <div class="card-block">
            <h6 class="m-b-20">إجمالى عدد الحجوزات</h6>
            <h2 class="text-right">
              <i class="fa fa-cart-plus f-left"></i><span>486</span>
            </h2>
            <p class="m-b-0">
              حجوزات غير خالصة<span class="f-right">351</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xl-3">
        <div class="card bg-c-green order-card">
          <div class="card-block">
            <h6 class="m-b-20">إجمالى الوارد</h6>
            <h2 class="text-right">
              <i class="fa fa-rocket f-left"></i><span>486</span>
            </h2>
            <p class="m-b-0">
              المطلوب تحصيله<span class="f-right">351</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xl-3">
        <div class="card bg-c-yellow order-card">
          <div class="card-block">
            <h6 class="m-b-20">إجمالى المصروفات</h6>
            <h2 class="text-right">
              <i class="fa fa-refresh f-left"></i><span>486</span>
            </h2>
            <p class="m-b-0">
              المطلوب دفعه<span class="f-right">351</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xl-3">
        <div class="card bg-c-pink order-card">
          <div class="card-block">
            <h6 class="m-b-20">اﻻرباح</h6>
            <h2 class="text-right">
              <i class="fa fa-credit-card f-left"></i><span>486</span>
            </h2>
            <p class="m-b-0">
              الخسائر<span class="f-right">351</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <h2 class="text-end section_title mb-5">اﻻحصائيات</h2>
    <div class="row">
      <div class="col-12 col-lg-6">
        <div class="small my-5">
          <pie-chart :data="[['إجمالى عدد الحجوزات ', 200], ['الحجوزات الغير خالصة', 23]]"></pie-chart>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="small my-5">
          <pie-chart :data="[['إجمالى عدد الحجوزات ', 200], ['عدد الرحلات اﻻضافية', 90]]"></pie-chart>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="small my-5">
          <pie-chart :data="[['إجمالى الواردات', 60], ['المطلوب تحصيله', 33]]"></pie-chart>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="small my-5">
          <pie-chart :data="[['إجمالى المصروفات', 90], ['المطلوب دفعه', 63]]"></pie-chart>
        </div>
      </div>
    </div>
    <div class="small my-5">
      <line-chart xtitle="المدة الزمنية" ytitle="القيمة" :data="income_chart_data"></line-chart>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: "Welcome",
  components: {},
  mounted() {
    this.income_chart_data = [
      {name: 'اﻻرباح ', data: {'2021-01-01': 0, '2021-01-10': 130, '2021-01-20': 100, '2021-01-30': 200}},
      {name: 'الخسائر ', data: {'2021-01-01': 100, '2021-01-10': 0, '2021-01-20': 0, '2021-01-30': 0}},
    ]
  },
  data() {
    return {
      start_date: [],
      end_date: [],
      flow : ref(['month', 'year', 'day']),
      income_chart_data: [],
    }
  },
  watch: {
    start_date: function (date) {
      console.log(typeof date, this.start_date)
    },
    end_date: function (date) {
      console.log(date, this.end_date)
    }
  },
  methods: {},
};
</script>

<style scoped>
.order-card {
  color: #fff;
}

.bg-c-blue {
  background: linear-gradient(45deg, #4099ff, #73b4ff);
}

.bg-c-green {
  background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}

.bg-c-yellow {
  background: linear-gradient(45deg, #FFB64D, #ffcb80);
}

.bg-c-pink {
  background: linear-gradient(45deg, #FF5370, #ff869a);
}

.card {
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.card .card-block {
  padding: 12px;
}

.order-card i {
  font-size: 26px;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}
</style>