<template>
  <div id="Extra-Trips">
    <div class="d-flex align-items-center">
      <h2 class="text-end section_title ml-2">الرحلات الاضافية</h2>
      <div class="mx-2">
        <button @click="service_reserve.type = 'company'"
                :class="{'btn btn-secondary':service_reserve.type === 'company', 'btn':true}">شركات
        </button>
      </div>
      <div class="mx-2">
        <button @click="service_reserve.type = 'individual'"
                :class="{'btn btn-secondary':service_reserve.type === 'individual' , 'btn':true}">أفراد
        </button>
      </div>
    </div>
    <form action="" class="text-end" method="post">
      <div class="title_container">
        <div class="d-flex align-items-center ">
          <div class="title"> بيانات العميل:</div>
          <hr style="width:100%"/>
        </div>
        <div class="section_container">
          <div class='row'>
            <div v-if="service_reserve.type === 'company'" class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                الشركة
              </label>
              <vue-select v-model="service_reserve.company_id" label-by="name" :options="company_options"
                          placeholder="الشركة"
                          close-on-select></vue-select>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                إسم العميل
              </label>
              <vue-select v-model="service_reserve.client_id" label-by="name" :options="client_options"
                          placeholder="العميل"
                          close-on-select></vue-select>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                موبايل العميل
              </label>
              <input v-model="service_reserve.mobile" type="text" min="0" placeholder="موبايل العميل"
                     class="form-control"/>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                عدد اﻻفراد
              </label>
              <input v-model="service_reserve.adult_no" type="number" min="0" placeholder="يرجى كتابة عدد اﻻفراد"
                     class="form-control"/>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                عدد اﻻطفال
              </label>
              <input v-model="service_reserve.kids_no" type="number" min="0" placeholder="يرجى كتابة عدد اﻻطفال"
                     class="form-control"/>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                الفندق
              </label>
              <vue-select v-model="service_reserve.hotel_vendor" label-by="name" :options="hotel_vendor_options"
                          placeholder="يرجى كتابة مقدم الخدمة"
                          close-on-select></vue-select>
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="title_container">-->
      <!--        <div class="d-flex align-items-center ">-->
      <!--          <div class="title">الإنتقاﻻت:</div>-->
      <!--          <hr style="width:100%"/>-->
      <!--        </div>-->
      <!--        <div class="section_container">-->
      <!--          <div class='row'>-->
      <!--            <div class='col-6 d-flex my-3 align-items-center  form-group'>-->
      <!--              <label class="label_width">-->
      <!--                مقدم الخدمة-->
      <!--              </label>-->
      <!--              <vue-select v-model="service_reserve.transfer_vendor" label-by="name" :options="transfer_vendor_options"-->
      <!--                          placeholder="يرجى كتابة مقدم الخدمة"-->
      <!--                          close-on-select></vue-select>-->
      <!--            </div>-->
      <!--            <div class='col-6 d-flex my-3 align-items-center  form-group'>-->
      <!--              <label class="label_width">-->
      <!--                وسيلة اﻻنتقال-->
      <!--              </label>-->
      <!--              <input v-model="service_reserve.transfer_service" type="text" placeholder="يرجى كتابة وسيلة اﻻنتقال"-->
      <!--                     class="form-control"/>-->
      <!--            </div>-->
      <!--            <div class='col-6 d-flex my-3 align-items-center  form-group'>-->
      <!--              <label class="label_width">-->
      <!--                عدد الكراسى-->
      <!--              </label>-->
      <!--              <input v-model="service_reserve.transfer_seats_qty" type="number" min="0"-->
      <!--                     placeholder="يرجى كتابة عدد الكراسى"-->
      <!--                     class="form-control"/>-->
      <!--            </div>-->
      <!--            <div class='col-6 d-flex my-3 align-items-center  form-group'>-->
      <!--              <label class="label_width">-->
      <!--                مكان التحرك-->
      <!--              </label>-->
      <!--              <input v-model="service_reserve.transfer_departure" type="text" placeholder="يرجى كتابة مكان التحرك"-->
      <!--                     class="form-control"/>-->
      <!--            </div>-->
      <!--            <div class='col-6 d-flex my-3 align-items-center  form-group'>-->
      <!--              <label class="label_width">-->
      <!--                تاريخ التحرك-->
      <!--              </label>-->
      <!--              <Datepicker utc :enableTimePicker="false" placeholder="تاريخ التحرك"-->
      <!--                          v-model="service_reserve.transfer_date" locale="en"/>-->
      <!--            </div>-->
      <!--            <div class='col-6 d-flex my-3 align-items-center  form-group'>-->
      <!--              <label class="label_width">-->
      <!--                الواجهة-->
      <!--              </label>-->
      <!--              <input v-model="service_reserve.transfer_destination" type="text" placeholder="يرجى كتابة مكان الواجهة"-->
      <!--                     class="form-control"/>-->
      <!--            </div>-->
      <!--          </div>-->

      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="title_container">-->
      <!--        <div class="d-flex align-items-center ">-->
      <!--          <div class="title">الإقامة :</div>-->
      <!--          <hr style="width:100%"/>-->
      <!--        </div>-->
      <!--        <div class="section_container">-->
      <!--          <div class='row'>-->

      <!--            <div class='col-6 d-flex my-3 align-items-center  form-group'>-->
      <!--              <label class="label_width">-->
      <!--                تاريخ الوصول-->
      <!--              </label>-->
      <!--              <Datepicker utc :enableTimePicker="false" placeholder="تاريخ الوصول"-->
      <!--                          v-model="service_reserve.hotel_arrival_date" locale="en"/>-->
      <!--            </div>-->
      <!--            <div class='col-6 d-flex my-3 align-items-center  form-group'>-->
      <!--              <label class="label_width">-->
      <!--                نوع اﻻقامة-->
      <!--              </label>-->
      <!--              <input class="form-control"/>-->
      <!--            </div>-->
      <!--            <div class='col-6 d-flex my-3 align-items-center  form-group'>-->
      <!--              <label class="label_width">-->
      <!--                عدد الغرف-->
      <!--              </label>-->
      <!--              <div class="d-flex">-->
      <!--                <input v-model="service_reserve.hotel_single_qty" style="width: 100px" type="number" min="0"-->
      <!--                       placeholder="سنجل"-->
      <!--                       class="form-control"/>-->
      <!--                <input v-model="service_reserve.hotel_double_qty" style="width: 100px" type="number" min="0"-->
      <!--                       placeholder="دبل"-->
      <!--                       class="form-control"/>-->
      <!--                <input v-model="service_reserve.hotel_triple_qty" style="width: 100px" type="number" min="0"-->
      <!--                       placeholder="تريبل"-->
      <!--                       class="form-control"/>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class='col-6 d-flex my-3 align-items-center  form-group'>-->
      <!--              <label class="label_width">-->
      <!--                عدد الليالى-->
      <!--              </label>-->
      <!--              <input v-model="service_reserve.hotel_nights_qty" type="number" min="0"-->
      <!--                     placeholder="يرجى كتابة عدد الليالى"-->
      <!--                     class="form-control"/>-->
      <!--            </div>-->
      <!--          </div>-->

      <!--        </div>-->
      <!--      </div>-->
            <div class="title_container">
              <div class="d-flex align-items-center ">
                <div class="title">التحصيل:</div>
                <hr style="width:100%"/>
              </div>
              <div class="section_container">
                <div class='row'>
                  <div class='col-6 d-flex my-3 align-items-center  form-group'>
                    <label class="label_width">
                      المبلغ اﻻجمالى
                    </label>
                    <input v-model="service_reserve.total" type="number" min="1" step="any"
                           placeholder="يرجى كتابة المبلغ اﻻجمالى" class="form-control"/>
                  </div>
                  <div class='col-6 d-flex my-3 align-items-center  form-group'>
                    <label class="label_width">
                      المقدم
                    </label>
                    <input v-model="service_reserve.paid_amount" type="number" min="1" step="any"
                           placeholder="يرجى كتابة المبلغ المقدم" class="form-control"/>
                  </div>
                  <div class='col-6 d-flex my-3 align-items-center  form-group'>
                    <label class="label_width">
                      على حساب
                    </label>
                    <vue-select v-model="service_reserve.account_id" label-by="name" :options="account_options"
                                placeholder="إلى حساب"
                                close-on-select></vue-select>
                  </div>
                </div>
              </div>
            </div>
      <button type="button" class="btn-primary mx-2" @click="addExtraTrip" >حجز </button>
      <button type="button" class="btn-secondary" @click="cancelExtraTrip">إالغاء </button>
    </form>
  </div>
</template>

<script>
import {VendorRepositoryFactory} from "@/services/repositories/VendorsApi/RepositoryFactory";
import {ClientRepositoryFactory} from "@/services/repositories/ClientsApi/RepositoryFactory";

let VendorsRepo = VendorRepositoryFactory.get('VendorsRepo')
let ClientRepo = ClientRepositoryFactory.get('ClientsRepo')
export default {
  name: 'Extra-Trips',
  data() {
    return {
      service_reserve: {
        type: 'company',
        company_id: null,
        client_id: null,
        adult_no: 3,
        kids_no: 4,
        transfer_vendor: '',
        transfer_service: '',
        transfer_seats_qty: '',
        transfer_date: [],
        transfer_departure: '',
        transfer_destination: '',
        hotel_vendor: '',
        hotel_service: '',
        hotel_arrival_date: '',
        hotel_nights_qty: '',
        hotel_single_qty: '',
        hotel_double_qty: '',
        hotel_triple_qty: '',
        total: '',
        paid_amount: '',
        account_id: '',
        confirmed: '',
      },
      transfer_vendor_options: [],
      hotel_vendor_options: [],
      company_options: [],
      client_options: [],
      account_options: [],
    }
  },
  mounted() {
    VendorsRepo.index().then((value) => {
      console.log(value)
      let vendors = value.data.data.vendors
      vendors.forEach((ele) => {
        if (ele.type == "transfer") {
          this.transfer_vendor_options.push({
            'id': ele.ID,
            'name': ele.name,
          })
        }
        if (ele.type == "hotel") {
          this.hotel_vendor_options.push({
            'id': ele.ID,
            'name': ele.name,
          })
        }
      })
    })
    ClientRepo.index().then((value) => {
      let clients = value.data.data.clients
      clients.forEach((ele) => {
        if (ele.type == "company") {
          this.company_options.push({
            'id': ele.ID,
            'name': ele.name,
          })

        }
        if (ele.type == "individual") {
          this.client_options.push({
            'id': ele.ID,
            'name': ele.name,
          })
        }
        console.log(this.company_options, this.client_options)
      })
    })
  },
  methods: {
    addExtraTrip: function () {

    },
    cancelExtraTrip: function () {

    }
  }
}
</script>

<style scoped>

</style>