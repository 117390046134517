<template>
  <div id="New-Reserve">
    <div class="d-flex align-items-center">
      <h2 class="text-end section_title ml-2">حجز جديد</h2>
      <div class="mx-2">
        <button @click="service_reserve.type = 'company'"
                :class="{'btn btn-secondary':service_reserve.type === 'company', 'btn':true}">شركات
        </button>
      </div>
      <div class="mx-2">
        <button @click="service_reserve.type = 'individual'"
                :class="{'btn btn-secondary':service_reserve.type === 'individual' , 'btn':true}">أفراد
        </button>
      </div>
    </div>
    <form action="" class="text-end" method="post">
      <div class="title_container">
        <div class="d-flex align-items-center ">
          <div class="title"> بيانات العميل:</div>
          <hr style="width:100%"/>
        </div>
        <div class="section_container">
          <div class='row'>
            <div v-if="service_reserve.type === 'company'" class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                الشركة
              </label>
              <vue-select v-model="service_reserve.company_id" label-by="name" :options="company_options"
                          placeholder="الشركة"
                          close-on-select></vue-select>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                العميل
              </label>
              <vue-select v-model="service_reserve.client_id" label-by="name" :options="client_options"
                          placeholder="العميل"
                          close-on-select></vue-select>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                عدد اﻻفراد
              </label>
              <input v-model="service_reserve.adult_no" type="number" min="0" placeholder="يرجى كتابة عدد اﻻفراد"
                     class="form-control"/>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                عدد اﻻطفال
              </label>
              <input v-model="service_reserve.kids_no" type="number" min="0" placeholder="يرجى كتابة عدد اﻻطفال"
                     class="form-control"/>
            </div>
          </div>
        </div>
      </div>
      <div class="title_container">
        <div class="d-flex align-items-center ">
          <div class="title">الإنتقاﻻت:</div>
          <hr style="width:100%"/>
        </div>
        <div class="section_container">
          <div class='row'>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                مقدم الخدمة
              </label>
              <vue-select v-model="service_reserve.transfer_vendor" label-by="name" :options="transfer_vendor_options"
                          placeholder="يرجى كتابة مقدم الخدمة"
                          close-on-select></vue-select>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                وسيلة اﻻنتقال
              </label>
              <input v-model="service_reserve.transfer_service" type="text" placeholder="يرجى كتابة وسيلة اﻻنتقال"
                     class="form-control"/>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                عدد الكراسى
              </label>
              <input v-model="service_reserve.transfer_seats_qty" type="number" min="0"
                     placeholder="يرجى كتابة عدد الكراسى"
                     class="form-control"/>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                مكان التحرك
              </label>
              <input v-model="service_reserve.transfer_departure" type="text" placeholder="يرجى كتابة مكان التحرك"
                     class="form-control"/>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                تاريخ التحرك
              </label>
              <Datepicker utc :enableTimePicker="false" placeholder="تاريخ التحرك"
                          v-model="service_reserve.transfer_date" locale="en"/>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                الواجهة
              </label>
              <input v-model="service_reserve.transfer_destination" type="text" placeholder="يرجى كتابة مكان الواجهة"
                     class="form-control"/>
            </div>
          </div>

        </div>
      </div>
      <div class="title_container">
        <div class="d-flex align-items-center ">
          <div class="title">الإقامة :</div>
          <hr style="width:100%"/>
        </div>
        <div class="section_container">
          <div class='row'>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                الفندق
              </label>
              <vue-select v-model="service_reserve.hotel_vendor" label-by="name" :options="hotel_vendor_options"
                          placeholder="يرجى كتابة مقدم الخدمة"
                          close-on-select></vue-select>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                تاريخ الوصول
              </label>
              <Datepicker utc :enableTimePicker="false" placeholder="تاريخ الوصول"
                          v-model="service_reserve.hotel_arrival_date" locale="en"/>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                نوع اﻻقامة
              </label>
              <input class="form-control"/>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                عدد الغرف
              </label>
              <div class="d-flex">
                <input v-model="service_reserve.hotel_single_qty" style="width: 100px" type="number" min="0"
                       placeholder="سنجل"
                       class="form-control"/>
                <input v-model="service_reserve.hotel_double_qty" style="width: 100px" type="number" min="0"
                       placeholder="دبل"
                       class="form-control"/>
                <input v-model="service_reserve.hotel_triple_qty" style="width: 100px" type="number" min="0"
                       placeholder="تريبل"
                       class="form-control"/>
              </div>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                عدد الليالى
              </label>
              <input v-model="service_reserve.hotel_nights_qty" type="number" min="0"
                     placeholder="يرجى كتابة عدد الليالى"
                     class="form-control"/>
            </div>
          </div>

        </div>
      </div>
      <div class="title_container">
        <div class="d-flex align-items-center ">
          <div class="title">التحصيل:</div>
          <hr style="width:100%"/>
        </div>
        <div class="section_container">
          <div class='row'>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                المبلغ اﻻجمالى
              </label>
              <input v-model="service_reserve.total" type="number" min="1" step="any"
                     placeholder="يرجى كتابة المبلغ اﻻجمالى" class="form-control"/>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                المقدم
              </label>
              <input v-model="service_reserve.paid_amount" type="number" min="1" step="any"
                     placeholder="يرجى كتابة المبلغ المقدم" class="form-control"/>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                على حساب
              </label>
              <vue-select v-model="service_reserve.account_id" label-by="name" :options="account_options"
                          placeholder="إلى حساب"
                          close-on-select></vue-select>
            </div>
            <div class='col-6 d-flex my-3 align-items-center  form-group'>
              <label class="label_width">
                تأكيد الحجز ؟
              </label>
              <vue-select v-model="service_reserve.type" label-by="name" :options="confirmed_options"
                          placeholder="حالة الحجز"
                          close-on-select></vue-select>
            </div>
          </div>
        </div>
      </div>
      <button type="button" @click="addNewReseve" class="float-right">حجز جديد</button>
    </form>
    <!--    <div>-->
    <!--            <ul>-->
    <!--              <li v-for="(value,key) in service_reserve"  >-->
    <!--                {{value}} : <strong>{{key}}</strong></li>-->
    <!--            </ul>-->
    <!--    </div>-->
  </div>
</template>

<script>
import {VendorRepositoryFactory} from "./../services/repositories/VendorsApi/RepositoryFactory.js";
import {ClientRepositoryFactory} from "@/services/repositories/ClientsApi/RepositoryFactory";
import {AccRepositoryFactory} from "@/services/repositories/AccountsApi/RepositoryFactory";
import {ReservationRepositoryFactory} from "@/services/repositories/ReservationApi/RepositoryFactory";

let VendorsRepo = VendorRepositoryFactory.get('VendorsRepo')
let ClientRepo = ClientRepositoryFactory.get('ClientsRepo')
let accountsRepo = AccRepositoryFactory.get('AccountsRepo')
let reservationRepo = ReservationRepositoryFactory.get('reservationRepo')


export default {
  name: 'New-Reserve',
  components: {},
  data() {
    return {
      mode: 'create',
      id: '',
      confirmed_options:[
        {'name': 'مؤكد' , 'value': '1'},
        {'name': 'غير مؤكد' , 'value': '0'}
      ],
      service_reserve: {
        type: 'company',
        company_id: null,
        client_id: null,
        adult_no: 3,
        kids_no: 4,
        transfer_vendor: '',
        transfer_service: '',
        transfer_seats_qty: '',
        transfer_date: [],
        transfer_departure: '',
        transfer_destination: '',
        hotel_vendor: '',
        hotel_service: '',
        hotel_arrival_date: '',
        hotel_nights_qty: '',
        hotel_single_qty: '',
        hotel_double_qty: '',
        hotel_triple_qty: '',
        total: '',
        paid_amount: '',
        account_id: '',
        confirmed: '',
      },
      transfer_vendor_options: [],
      hotel_vendor_options: [],
      company_options: [],
      client_options: [],
      account_options: [],
    }
  },
  mounted: function () {
    VendorsRepo.index().then((value) => {
      console.log(value)
      let vendors = value.data.data.vendors
      vendors.forEach((ele) => {
        if (ele.type == "transfer") {
          this.transfer_vendor_options.push({
            'id': ele.ID,
            'name': ele.name,
          })
        }
        if (ele.type == "hotel") {
          this.hotel_vendor_options.push({
            'id': ele.ID,
            'name': ele.name,
          })
        }
      })
    })
    ClientRepo.index().then((value) => {
      let clients = value.data.data.clients
      clients.forEach((ele) => {
        if (ele.type == "company") {
          this.company_options.push({
            'id': ele.ID,
            'name': ele.name,
          })

        }
        if (ele.type == "individual") {
          this.client_options.push({
            'id': ele.ID,
            'name': ele.name,
          })
        }
        console.log(this.company_options, this.client_options)
      })
    })
    accountsRepo.index().then((value) => {
      let accounts = value.data.data.accounts
      accounts.forEach((ele) => {
        this.account_options.push({
          'id': ele.ID,
          'name': ele.name,
        })
      })
    })
    if (this.$route.params.id) {
      reservationRepo.get(this.$route.params.id).then((val) => {
        if (val.data.data != 'NULL') {
          let data = val.data.data
          this.mode = 'update'
          this.id = data.ID
          if (data.adult_no)
            this.service_reserve.adult_no = data.adult_no
          if (data.client_id)
            this.service_reserve.client_id = data.client_id
          if (data.company_id)
            this.service_reserve.company_id = data.company_id
          if (data.total)
            this.service_reserve.total = data.total
          // if (data.confirmed)
          //   this.service_reserve.confirmed.value = data.confirmed
          if (data.hotel_arrival_date)
            this.service_reserve.hotel_arrival_date = data.hotel_arrival_date
          if (data.hotel_double_qty)
            this.service_reserve.hotel_double_qty = data.hotel_double_qty
          if (data.hotel_nights_qty)
            this.service_reserve.hotel_nights_qty = data.hotel_nights_qty
          if (data.hotel_service)
            this.service_reserve.hotel_service = data.hotel_service
          if (data.hotel_single_qty)
            this.service_reserve.hotel_single_qty = data.hotel_single_qty
          if (data.hotel_triple_qty)
            this.service_reserve.hotel_triple_qty = data.hotel_triple_qty
          if (data.hotel_vendor)
            this.service_reserve.hotel_vendor = data.hotel_vendor
          if (data.kids_no)
            this.service_reserve.kids_no = data.kids_no
          if (data.total)
            this.service_reserve.total = data.total
          if (data.transfer_date)
            this.service_reserve.transfer_date = data.transfer_date
          if (data.transfer_departure)
            this.service_reserve.transfer_departure = data.transfer_departure
          if (data.transfer_destination)
            this.service_reserve.transfer_destination = data.transfer_destination
          if (data.transfer_seats_qty)
            this.service_reserve.transfer_seats_qty = data.transfer_seats_qty
          if (data.transfer_service)
            this.service_reserve.transfer_service = data.transfer_service
          if (data.transfer_vendor)
            this.service_reserve.transfer_vendor = data.transfer_vendor
          if (data.type)
            this.service_reserve.type = data.type
          console.log(data)
        }
      })
    }
  },
  methods: {
    addNewReseve: function () {
      let data = new FormData();
      data.append("type", this.service_reserve.type);
      data.append("confirmed", this.service_reserve.confirmed);
      if (this.service_reserve.company_id)
        data.append("company_id", this.service_reserve.company_id.id);
      if (this.service_reserve.client_id)
        data.append("client_id", this.service_reserve.client_id.id);
      if (this.service_reserve.transfer_vendor)
        data.append("transfer_vendor", this.service_reserve.transfer_vendor.id);
      data.append("transfer_service", this.service_reserve.transfer_service);
      data.append("transfer_seats_qty", this.service_reserve.transfer_seats_qty);
      data.append("transfer_date", this.service_reserve.transfer_date);
      data.append("transfer_departure", this.service_reserve.transfer_departure);
      data.append("transfer_destination", this.service_reserve.transfer_destination);
      if (this.service_reserve.hotel_vendor)
        data.append("hotel_vendor", this.service_reserve.hotel_vendor.id);
      //TODO hotel service
      data.append("hotel_service", this.service_reserve.hotel_service);
      data.append("hotel_arrival_date", this.service_reserve.hotel_arrival_date);
      data.append("hotel_nights_qty", this.service_reserve.hotel_nights_qty);
      data.append("hotel_single_qty", this.service_reserve.hotel_single_qty);
      data.append("hotel_double_qty", this.service_reserve.hotel_double_qty);
      data.append("hotel_triple_qty", this.service_reserve.hotel_triple_qty);
      data.append("kids_no", this.service_reserve.kids_no);
      data.append("adult_no", this.service_reserve.adult_no);
      data.append("total", this.service_reserve.total);
      data.append("paid_amount", this.service_reserve.paid_amount);
      data.append("account_id", this.service_reserve.account_id);
      reservationRepo.add(data).then((val) => {
        alert(val.data.message)
        if (val.data.result != "FAIULER") {
          this.$router.replace(`/reservations`);
        }
      });
    },
  }
}
</script>

<style scoped>

</style>