
import VendorsRepo from './VendorsRepo';

const repositories = {
    VendorsRepo: VendorsRepo,
}

export const VendorRepositoryFactory = {
    get: name => repositories[name],
};
