<template>
  <div id="expanse">
    <h2 class="text-end section_title mb-5">الحسابات - اﻻيرادات</h2>
    <div class="table_container">
      <table
          v-if="!addNewLine"
          width="100%"
          dir="rtl"
          class="table table-bordered table-striped all_expanse_table"
      >
        <tbody>
        <tr>
          <th class="text-end">رقم القيد</th>
          <th class="text-end"> من حساب</th>
          <th class="text-end"> إلى حساب</th>
          <th class="text-end"> العميل</th>
          <th class="text-end">رقم الحجز</th>
          <th class="text-end">القيمة</th>
          <th class="text-end">ملاحظات</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="income in incomes" :key="income.ID" class="disabled_input">
          <td width="7%">
            <input
                style="pointer-events: none !important;color: gray!important;border: gray!important;background-color: transparent;!important;"
                v-model="income.ID" placeholder="رقم القيد"/>
          </td>
          <td class="edit_mode_false" v-if="income.credit_account">
            <input v-model="income.credit_account.name" placeholder="من حساب"/>
          </td>
          <td class="edit_mode_false" v-else></td>
          <td class="edit_mode_false" v-if="income.debit_account">
            <input v-model="income.debit_account.name" placeholder="إلى حساب"/>
          </td>
          <td class="edit_mode_false" v-else></td>
          <td class="edit_mode_false" v-if="income.client">
            <input v-model="income.client.name" placeholder="المورد"/>
          </td>
          <td class="edit_mode_false" v-else></td>
          <td class="edit_mode_false" v-if="income.reservation_id">
            <input v-model="income.reservation_id" placeholder="رقم الحجز"/>
          </td>
          <td class="edit_mode_false" v-else></td>
          <td class="edit_mode_true">
            <vue-select v-model="income.credit_account" label-by="name" :options="credit_options"
                        placeholder="من حساب"
                        @input="setSelected"
                        close-on-select></vue-select>
          </td>
          <td class="edit_mode_true">
            <vue-select v-model="income.debit_account" label-by="name" :options="debit_options"
                        placeholder="إلى حساب"
                        @input="setSelected"
                        close-on-select></vue-select>
          </td>
          <td class="edit_mode_true">
            <vue-select v-model="income.client" label-by="name" :options="client_options"
                        placeholder="المورد"
                        @input="setSelected"
                        close-on-select></vue-select>
          </td>
          <td class="edit_mode_true">
            <vue-select v-model="income.reservation" label-by="id" :options="reserve_options"
                        placeholder="رقم الحجز"
                        @input="setSelected"
                        close-on-select></vue-select>
          </td>
          <td width="10%">
            <input v-model="income.amount" placeholder="القيمة"/>
          </td>
          <td>
            <input v-model="income.note" placeholder="ملاحظات"/>
          </td>
          <td>
            <button class="edit_btn btn btn-dark" @click="editIncome($event, income)"><i class="bi bi-gear-fill"></i>
            </button>
            <button class="save_btn btn btn-success" @click="updateIncome($event, income)">
              حفظ
            </button>
          </td>
          <td>
            <button class="btn btn-danger" @click="deleteIncome(income.ID)"><i class="bi bi-trash-fill"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div>
      <add-new-line :repo="repo" :addNewLine="addNewLine" :lineType="lineType"/>
    </div>
    <div class="text-end py-3">
      <!--      <button class="btn btn-secondary" v-if="addNewLine" @click="addNewLine = false">إلغاء اﻻضافة</button>-->
      <button class="btn btn-primary" v-if="!addNewLine" @click="addLineForNewIncome">إضافة إيراد</button>
    </div>
  </div>
</template>

<script>
import AddNewLine from './../components/AddNewLine.vue'
import {RepositoryFactory} from "./../services/repositories/PaymentsApi/RepositoryFactory";
import {ClientRepositoryFactory} from "./../services/repositories/ClientsApi/RepositoryFactory";
import {AccRepositoryFactory} from "./../services/repositories/AccountsApi/RepositoryFactory";
import {ReservationRepositoryFactory} from "@/services/repositories/ReservationApi/RepositoryFactory";

let incomesRepo = RepositoryFactory.get('incomesRepo')
let accountsRepo = AccRepositoryFactory.get('AccountsRepo')
let reservationRepo = ReservationRepositoryFactory.get('reservationRepo')
let ClientsRepo = ClientRepositoryFactory.get('ClientsRepo')
export default {
  name: 'Company',
  components: {
    AddNewLine
  },
  data() {
    return {
      items: [],
      addNewLine: false,
      incomes: [],
      debit_options: [],
      credit_options: [],
      client_options: [],
      reserve_options: [],
      lineType: 'إيراد',
      repo: incomesRepo,
    }
  },
  methods: {
    addLineForNewIncome: function () {
      // if (document.querySelectorAll('.all_expanse_table tr:not(.disabled_input)').length > 1) {
      //   let isConfirmed = confirm(`لم يتم الحفظ , هل تريد اﻻستكمال ؟`)
      //   if (!isConfirmed) {
      //     return
      //   }
      // }
      // this.addNewLine = true
      this.$router.replace('/add-income')
    },
    editIncome: function (ev, income) {
      // ev.path[2].classList.remove("disabled_input")
      this.$router.replace(`/add-income/${income.ID}`)
    },
    updateIncome: function (ev, income) {
      console.log(income)
      ev.path[2].classList.add("disabled_input")
      let data = new FormData()
      data.append('type', 'expense')
      if (income.amount != null)
        data.append('amount', income.amount)
      if (income.note != null)
        data.append('note', income.note)
      if (income.debit_account != null)
        data.append('debit_id', income.debit_account.id)
      if (income.credit_account != null)
        data.append('credit_id', income.credit_account.id)
      if (income.client != null)
        data.append('client_id', income.client.id)
      if (income.reservation != null)
        data.append('reservation_id', income.reservation.id)
      let isConfirmed = confirm(`هل توافق على اجراء التعديل ؟`)
      if (isConfirmed) {
        incomesRepo.update(data, income.ID).then((val) => {
          console.log(val)
          alert('تم التعديل بنجاح')
          // this.$router.go()
        })
      } else {
        this.$router.go()
      }
    },
    deleteIncome: function (id) {
      let isConfirmed = confirm(`هل توافق على اجراء التعديل ؟`)
      if (isConfirmed) {
        incomesRepo.delete(id).then(() => {
          alert('تم التعديل بنجاح')
          this.$router.go()
        })
      }
    },
    searchForDebitsAcc: function (e) {
      accountsRepo.index(e.target.value).then(() => {
        // this.transfer_debit_options = results.data.data.accounts
        this.transfer_debit_options = {
          'name': 'new name',
          'id': 5
        }
        console.log(this.debit_accounts)
      })
    },
    searchForCreditsAcc: function (e) {
      console.log(e.target.value)
    },
    setSelected: function (e) {
      console.log(e.target.value)
    }
  },
  mounted: function () {
    incomesRepo.index().then((value) => {
      console.log(value)
      this.incomes = value.data.data.payments
      console.log(this.incomes)
      this.incomes.forEach(function (ele, index, arr) {
        if (ele.debit_id != 0 || ele.debit_id != '') {
          let debit_id = parseInt(ele.debit_id)
          accountsRepo.get(debit_id).then(function (val) {
                if (val.data.data != "NULL")
                  arr[index].debit_account = {
                    'id': ele.debit_id,
                    'name': val.data.data.name,
                  }
              }
          )
        }
        if (ele.credit_id != 0 || ele.credit_id != '') {
          let credit_id = parseInt(ele.credit_id)
          accountsRepo.get(credit_id).then(function (val) {
                if (val.data.data != "NULL")
                  arr[index].credit_account = {
                    'id': ele.credit_id,
                    'name': val.data.data.name,
                  }
              }
          )
        }
      })
    })
    accountsRepo.index().then((value) => {
      let accounts = value.data.data.accounts
      accounts.forEach((ele) => {
        this.credit_options.push({
          'id': ele.ID,
          'name': ele.name,
        })
        this.debit_options.push({
          'id': ele.ID,
          'name': ele.name,
        })
      })
    })
    reservationRepo.index().then((value) => {
      let reservations = value.data.data.reservations
      reservations.forEach((ele) => {
        this.reserve_options.push({
          'id': ele.ID,
        })
      })
    })
    ClientsRepo.index().then((value) => {
      let clients = value.data.data.clients
      clients.forEach((ele) => {
        this.client_options.push({
          'id': ele.ID,
          'name': ele.name,
        })
      })
    })
  }
  ,
}
</script>

<style>

</style>