<template>
  <div class="add-new-line pb-5" v-if="addNewLine">
    <div class="table_container">
      <form action="" class="text-end" method="post">
        <div class="title_container">
          <div class="d-flex align-items-center ">
            <div class="title"> بيانات {{ lineType }}:</div>
            <hr style="width:100%"/>
          </div>
          <div class="section_container">
            <div class='row'>
              <div v-if="lineType === 'عميل'|| lineType === 'مورد' || lineType === 'حساب'"
                   class='col-6 d-flex my-3 align-items-center  form-group'>
                <label class="label_width">
                  اﻻسم
                </label>
                <input v-model="name"
                       placeholder="اﻻسم"/>
              </div>
              <div v-if="lineType === 'حساب'" class='col-6 d-flex my-3 align-items-center  form-group'>
                <label class="label_width">
                  رقم الحساب
                </label>
                <input v-model="account_number" placeholder="رقم الحساب"/>
              </div>
              <div v-if="lineType === 'عميل'|| lineType === 'مورد'"
                   class='col-6 d-flex my-3 align-items-center  form-group'>
                <label class="label_width">
                  الموبايل
                </label>
                <input v-model="mobile"
                       placeholder="الموبايل"/>
              </div>
              <div v-if="lineType === 'مورد'"
                   class='col-6 d-flex my-3 align-items-center  form-group'>
                <label class="label_width">
                  نوع البيزنس
                </label>
                <vue-select v-model="type" label-by="name" :options="transfer_vendor_options"
                            placeholder="نوع مقدم الخدمة"
                            close-on-select></vue-select>
              </div>
              <div v-if="lineType === 'عميل'"
                   class='col-6 d-flex my-3 align-items-center  form-group'>
                <label class="label_width">
                  نوع العميل
                </label>
                <vue-select v-model="type" label-by="name" :options="transfer_client_options"
                            placeholder="نوع العميل"
                            close-on-select></vue-select>
              </div>
              <div v-if="lineType === 'عميل'|| lineType === 'مورد'"
                   class='col-6 d-flex my-3 align-items-center  form-group'>
                <label class="label_width">
                  العنوان
                </label>
                <input v-model="address"
                       placeholder="العنوان"/>
              </div>
              <div v-if="lineType === 'عميل'|| lineType === 'مورد'"
                   class='col-6 d-flex my-3 align-items-center  form-group'>
                <label class="label_width">
                  المدينة
                </label>
                <input v-model="city" placeholder="المدينة"/>
              </div>
              <div v-if="lineType === 'عميل'|| lineType === 'مورد'"
                   class='col-6 d-flex my-3 align-items-center  form-group'>
                <label class="label_width">
                  اﻻيميل
                </label>
                <input v-model="email" placeholder="اﻻيميل"/>
              </div>
              <div v-if="lineType === 'مصروف'|| lineType === 'وارد'"
                   class='col-6 d-flex my-3 align-items-center  form-group'>
                <label class="label_width">
                  من حساب
                </label>
                <vue-select v-model="credit_id" label-by="name" :options="credit_options"
                            placeholder="من حساب"
                            close-on-select></vue-select>
              </div>
              <div v-if="lineType === 'مصروف'|| lineType === 'وارد'"
                   class='col-6 d-flex my-3 align-items-center  form-group'>
                <label class="label_width">
                  إلى حساب
                </label>
                <vue-select v-model="debit_id" label-by="name" :options="debit_options"
                            placeholder="إلى حساب"
                            close-on-select></vue-select>
              </div>
              <div v-if="lineType === 'مصروف'|| lineType === 'وارد'"
                   class='col-6 d-flex my-3 align-items-center  form-group'>
                <label class="label_width">
                  القيمة
                </label>
                <input v-model="amount" placeholder="القيمة"/>
              </div>
              <div v-if="lineType === 'مصروف'|| lineType === 'وارد'"
                   class='col-6 d-flex my-3 align-items-center  form-group'>
                <label class="label_width">
                  ملاحظات
                </label>
                <input v-model="note" placeholder="ملاحظات"/>
              </div>
              <div v-if="lineType === 'مصروف'|| lineType === 'وارد'"
                   class='col-6 d-flex my-3 align-items-center  form-group'>
                <label class="label_width">
                  رقم الحجز
                </label>
                <vue-select v-model="reservation_id" label-by="id" :options="reserve_options"
                            placeholder="رقم الحجز"
                            close-on-select></vue-select>
              </div>
              <div v-if="lineType === 'مصروف'"
                   class='col-6 d-flex my-3 align-items-center  form-group'>
                <label class="label_width">
                  المورد
                </label>
                <vue-select v-model="vendor_id" label-by="name" :options="vendor_options"
                            placeholder="المورد"
                            close-on-select></vue-select>
              </div>
              <div v-if="lineType === 'وارد'"
                   class='col-6 d-flex my-3 align-items-center  form-group'>
                <label class="label_width">
                  العميل
                </label>
                <vue-select v-model="client_id" label-by="name" :options="client_options"
                            placeholder="العميل"
                            close-on-select></vue-select>
              </div>
            </div>
          </div>
        </div>
        <div class="text-start">
          <button class="btn btn-primary mx-2" v-if="mode == 'create'" @click="addRecord"> إضافة-{{ lineType }}</button>
          <button class="btn btn-primary mx-2" v-else @click="addRecord"> تعديل-{{ lineType }}</button>
          <button class="btn btn-secondary" @click="cancelAdd">إلغاء اﻻضافة</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {AccRepositoryFactory} from "@/services/repositories/AccountsApi/RepositoryFactory";
import {ReservationRepositoryFactory} from "@/services/repositories/ReservationApi/RepositoryFactory";
import {VendorRepositoryFactory} from "@/services/repositories/VendorsApi/RepositoryFactory";
import {ClientRepositoryFactory} from "@/services/repositories/ClientsApi/RepositoryFactory";

let accountsRepo = AccRepositoryFactory.get('AccountsRepo')
let reservationRepo = ReservationRepositoryFactory.get('reservationRepo')
let VendorsRepo = VendorRepositoryFactory.get('VendorsRepo')
let clientsRepo = ClientRepositoryFactory.get('ClientsRepo')

export default {
  name: "HelloWorld",
  props: {
    addNewLine: Boolean,
    lineType: String,
    repo: Object,
    cancel: String
  },
  data() {
    return {
      mode: 'create',
      id: '',
      email: "",
      city: "",
      type: "",
      transfer_vendor_options: [
        {
          name: 'انتقاﻻت',
          value: 'transfer'
        },
        {
          name: 'فندق',
          value: 'hotel'
        },
        {
          name: 'اخرى',
          value: 'other'
        },
      ],
      transfer_client_options: [
        {
          name: 'شركات',
          value: 'company'
        },
        {
          name: 'أفراد',
          value: 'individual'
        },
      ],
      address: "",
      mobile: "",
      name: "",
      credit_id: "",
      debit_id: "",
      amount: "",
      note: "",
      reservation_id: "",
      vendor_id: "",
      reserve_id: "",
      account_number: "",
      debit_options: [],
      credit_options: [],
      reserve_options: [],
      vendor_options: [],
      client_options: []
    };
  },
  methods: {
    addRecord: function (e) {
      e.preventDefault()
      let data = new FormData();
      if (this.lineType === "مورد" || this.lineType === "عميل") {
        data.append("name", this.name);
        data.append("email", this.email);
        data.append("city", this.city);
        data.append("address", this.address);
        data.append("mobile", this.mobile);
        data.append("type", this.type.value);
        if (this.mode == 'create') {
          this.repo.add(data).then((val) => {
            alert(val.data.message)
            if (val.data.result != "FAIULER") {
              this.$router.replace(`/${this.cancel}`);
            }
          });
        } else {
          this.repo.update(data, this.id).then((val) => {
            alert(val.data.message)
            if (val.data.result != "FAIULER") {
              this.$router.replace(`/${this.cancel}`);
            }
          });
        }
      }
      if (this.lineType === "مصروف") {
        data.append("debit_id", parseInt(this.debit_id.id));
        data.append("credit_id", parseInt(this.credit_id.id));
        data.append("amount", this.amount);
        data.append("reservation_id", parseInt(this.reservation_id.id));
        data.append("note", this.note);
        data.append("vendor_id", parseInt(this.vendor_id.id));
        data.append("type", 'expense');
        if (this.mode == 'create') {
          this.repo.add(data).then((val) => {
            alert(val.data.message)
            if (val.data.result != "FAIULER") {
              this.$router.replace(`/${this.cancel}`);
            }
          });
        } else {
          this.repo.update(data, this.id).then((val) => {
            alert(val.data.message)
            if (val.data.result != "FAIULER") {
              this.$router.replace(`/${this.cancel}`);
            }
          });
        }
      }
      if (this.lineType === 'وارد') {
        data.append("debit_id", parseInt(this.debit_id.id));
        data.append("credit_id", parseInt(this.credit_id.id));
        data.append("amount", this.amount);
        data.append("reservation_id", parseInt(this.reservation_id.id));
        data.append("note", this.note);
        data.append("client_id", parseInt(this.client_id.id));
        data.append("type", 'income');
        if (this.mode == 'create') {
          this.repo.add(data).then((val) => {
            alert(val.data.message)
            if (val.data.result != "FAIULER") {
              this.$router.replace(`/${this.cancel}`);
            }
          });
        } else {
          this.repo.update(data, this.id).then((val) => {
            alert(val.data.message)
            if (val.data.result != "FAIULER") {
              this.$router.replace(`/${this.cancel}`);
            }
          });
        }
      }
      if (this.lineType === "حساب") {
        data.append("name", this.name);
        data.append("number", this.account_number);
        if (this.mode == 'create') {
          this.repo.add(data).then((val) => {
            alert(val.data.message)
            if (val.data.result != "FAIULER") {
              this.$router.replace(`/${this.cancel}`);
            }
          });
        } else {
          this.repo.update(data, this.id).then((val) => {
            console.log(val)
            alert(val.data.message)
            if (val.data.result != "FAIULER") {
              this.$router.replace(`/${this.cancel}`);
            }
          });
        }

      }
    },
    cancelAdd: function (e) {
      e.preventDefault()
      this.$router.replace(`/${this.cancel}`)
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.repo.get(this.$route.params.id).then((val) => {
        if (val.data.data != 'NULL') {
          let data = val.data.data
          this.mode = 'update'
          this.id = data.ID
          if (data.name)
            this.name = data.name
          if (data.email)
            this.email = data.email
          if (data.city)
            this.city = data.city
          if (data.type) {
            if (data.type == 'hotel') {
              this.type = {
                name: 'فندق',
                value: 'hotel'
              }
            }
            if (data.type == 'transfer') {
              this.type = {
                name: 'انتقاﻻت',
                value: 'transfer'
              }
            }
            if (data.type == 'other') {
              this.type = {
                name: 'اخرى',
                value: 'other'
              }
            }
            if (data.type == 'company') {
              this.type = {
                name: 'شركات',
                value: 'company'
              }
            }
            if (data.type == 'individual') {
              this.type = {
                name: 'أفراد',
                value: 'individual'
              }
            }
          }

          if (data.address)
            this.address = data.address
          if (data.mobile)
            this.mobile = data.mobile
          // if (data.credit_id) {
          //   this.credit_id = {
          //     'id': data.credit_account.ID,
          //     'name': data.credit_account.name
          //   }
          // }
          if (data.credit_id) {
            this.credit_id = {
              'id': parseInt(data.credit_id)
            }
          }
          if (data.debit_id)
            this.debit_id = {
              'id': parseInt(data.debit_id)
            }
          if (data.amount)
            this.amount = data.amount
          if (data.note)
            this.note = data.note
          if (data.reservation_id)
            this.reservation_id = {
              'id': parseInt(data.reservation_id)
            }
          if (data.vendor_id)
            this.vendor_id = {
              'id': parseInt(data.vendor_id)
            }
          if (data.client_id)
            this.client_id = {
              'id': parseInt(data.client_id)
            }
          if (data.number)
            this.account_number = data.number
          console.log(data)
        }
      })
    }
    if (this.lineType === "مصروف" || this.lineType === "وارد") {
      accountsRepo.index().then((value) => {
        let accounts = value.data.data.accounts
        accounts.forEach((ele) => {
          this.credit_options.push({
            'id': ele.ID,
            'name': ele.name,
          })
          this.debit_options.push({
            'id': ele.ID,
            'name': ele.name,
          })
        })
      })
      reservationRepo.index().then((value) => {
        let reservations = value.data.data.reservations
        reservations.forEach((ele) => {
          this.reserve_options.push({
            'id': ele.ID,
          })
        })
      })
      VendorsRepo.index().then((value) => {
        let vendors = value.data.data.vendors
        vendors.forEach((ele) => {
          this.vendor_options.push({
            'id': ele.ID,
            'name': ele.name,
          })
        })
      })
      clientsRepo.index().then((value) => {
        let clients = value.data.data.clients
        clients.forEach((ele) => {
          this.client_options.push({
            'id': ele.ID,
            'name': ele.name,
          })
        })
      })
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/* input {
  width: 100%;
} */
.route_container {
  direction: rtl;
}

/* .table_container {
  overflow-x: scroll;
} */
</style>
