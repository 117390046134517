import Repository from './../Repository';

const resource = "/auth/users";
export default {
    get(id) {
        return Repository.get(`${resource}/${id}/`);
    },
    update(id, data) {
        return Repository.put(`${resource}/${id}/`, data);
    },
    changeEmail(data) {
        return Repository.put(`/user/email/`, data);
    },
    changePassword(data) {
        return Repository.put(`/user/password/`, data);
    },
}
