
import expansesRepo from './ExpansesRepo';
import incomesRepo from './RevenuesRepo';

const repositories = {
    expansesRepo: expansesRepo,
    incomesRepo: incomesRepo,
}

export const RepositoryFactory = {
    get: name => repositories[name],
};
